import Vue from 'vue'
import Vuex from 'vuex'
// 挂载vuex
Vue.use(Vuex)

// 创建vuex对象并向外暴露
export default new Vuex.Store({
    // 全局属性变量
    state: {
        token: window.localStorage.getItem("Authorization"),
        defaultPageSize: window.localStorage.getItem("defaultPageSize") || '50',
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        ScreenWidth:"",
        ScreenHeight:"",
        //0元购
        FirstOrderEditShow: false,
        FirstOrderId: '',
        FirstOrderFresh: false,
        //商品
        ProductEditShow: false,
        ProductId: '',
        ProductFresh: false,
        //海报
        PosterEditShow: false,
        PosterId: '',
        PosterFresh: false,
        //搜索标签
        SearchEditShow: false,
        SearchId: '',
        SearchFresh: false,
        //分类
        CategoryEditShow: false,
        CategoryId: '',
        CategoryFresh: false,
        //发圈
        PostEditShow: false,
        PostId: '',
        PostFresh: false,
        //活动
        ActivityEditShow: false,
        ActivityId: '',
        ActivityFresh: false,
        //试用测评
        TrialEditShow: false,
        TrialDataShow: false,
        TrialId: '',
        TrialFresh: false,
    },
    // 全局同步方法, 调用方法,this.$store.commit("xxx")
    mutations: {
        changeToken(state, token) {
            state.token = token
        },
        changedefaultPageSize(state, defaultPageSize) {
            state.defaultPageSize = defaultPageSize
        },
        changeScreenWidth(state, ScreenWidth) {
            state.ScreenWidth = ScreenWidth
        },
        changeScreenHeight(state, ScreenHeight) {
            state.ScreenHeight = ScreenHeight
        },
        //0元购
        changeFirstOrderEditShow(state, FirstOrderEditShow) {
            state.FirstOrderEditShow = FirstOrderEditShow
        },
        changeFirstOrderId(state, FirstOrderId) {
            state.FirstOrderId = FirstOrderId
        },
        changeFirstOrderFresh(state, FirstOrderFresh) {
            state.FirstOrderFresh = FirstOrderFresh
        },
        //商品
        changeProductEditShow(state, ProductEditShow) {
            state.ProductEditShow = ProductEditShow
        },
        changeProductId(state, ProductId) {
            state.ProductId = ProductId
        },
        changeProductFresh(state, ProductFresh) {
            state.ProductFresh = ProductFresh
        },
        //海报
        changePosterEditShow(state, PosterEditShow) {
            state.PosterEditShow = PosterEditShow
        },
        changePosterId(state, PosterId) {
            state.PosterId = PosterId
        },
        changePosterFresh(state, PosterFresh) {
            state.PosterFresh = PosterFresh
        },
        //搜索标签
        changeSearchEditShow(state, SearchEditShow) {
            state.SearchEditShow = SearchEditShow
        },
        changeSearchId(state, SearchId) {
            state.SearchId = SearchId
        },
        changeSearchFresh(state, SearchFresh) {
            state.SearchFresh = SearchFresh
        },
        //分类
        changeCategoryEditShow(state, CategoryEditShow) {
            state.CategoryEditShow = CategoryEditShow
        },
        changeCategoryId(state, CategoryId) {
            state.CategoryId = CategoryId
        },
        changeCategoryFresh(state, CategoryFresh) {
            state.CategoryFresh = CategoryFresh
        },
        //发圈
        changePostEditShow(state, PostEditShow) {
            state.PostEditShow = PostEditShow
        },
        changePostId(state, PostId) {
            state.PostId = PostId
        },
        changePostFresh(state, PostFresh) {
            state.PostFresh = PostFresh
        },
        //活动
        changeActivityEditShow(state, ActivityEditShow) {
            state.ActivityEditShow = ActivityEditShow
        },
        changeActivityId(state, ActivityId) {
            state.ActivityId = ActivityId
        },
        changeActivityFresh(state, ActivityFresh) {
            state.ActivityFresh = ActivityFresh
        },
        //试用测试
        changeTrialEditShow(state, TrialEditShow) {
            state.TrialEditShow = TrialEditShow
        },
        changeTrialDataShow(state, TrialDataShow) {
            state.TrialDataShow = TrialDataShow
        },
        changeTrialId(state, TrialId) {
            state.TrialId = TrialId
        },
        changeTrialFresh(state, TrialFresh) {
            state.TrialFresh = TrialFresh
        },
    },
    // 异步方法 调用方法,this.$store.dispatch("xxx") 
    actions: {
    },
    // Vuex属性计算,在视图里面当变量使用
    getters: {
    },
    // 模块化注册
    modules: {
    }
})