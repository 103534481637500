import Vue from 'vue'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue';
import router from './routers'
import store from './store'
import App from './App.vue'
import vcolorpicker from 'vcolorpicker'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
import 'core-js'
import './assets/common.less'

Vue.use(VueDirectiveImagePreviewer, {
  background: {     // or : background: '#000'
    color: '#000' // or rgba or rgb     // or image: 'url(xxx)'
  },
  // transition
  animate: {
    duration: 600,
    delay: 500
  },
  // loading (not supported)
  loading: {
    image: ''
  },
  zIndex: 9999,
  // cursor(css)
  cursor: 'pointer',
  clickMethod: 'doubleClick'   // click or doubleClick(not supported)
})
Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.use(Antd);
Vue.use(vcolorpicker)
import axios from 'axios'
Vue.prototype.axios = axios

//公共token
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem("Authorization");
  return config;
}, error => {
  console.log(error)
})
//状态码
var code = function (res) {
  if (res.data.resultCode == 0) {
    return true;
  } else if (res.data.resultCode == 20001) {
    window.localStorage.setItem("Authorization", "");
    window.localStorage.setItem("name", "");
    window.localStorage.setItem("id", "");
    axios.defaults.headers.post["Authorization"] = "";
    message.error(res.data.resultMsg);
    store.commit("changeToken", '')
    store.commit("changeFirstOrderEditShow", false)
    store.commit("changeProductEditShow", false)
    store.commit("changePosterEditShow", false)
    store.commit("changeSearchEditShow", false)
    store.commit("changeCategoryEditShow", false)
    store.commit("changePostEditShow", false)
    router.push("/login");
    return false;
  } else {
    message.error(res.data.resultMsg);
    return false;
  }
}
Vue.prototype.$code = code
//退出登录
var logOut = function () {
  window.localStorage.setItem("Authorization", "");
  window.localStorage.setItem("nickName", "");
  window.localStorage.setItem("appId", "");
  window.localStorage.setItem("id", "");
  axios.defaults.headers.post["Authorization"] = "";
  router.push("/login");
};
Vue.prototype.$logOut = logOut;

//分类列表
var CategoryList = function() {
  return new Promise(function (resolve) {
  axios.get("/AdminApi/ProductCustomizeCategory/list")
    .then((res) => {
      if (code(res)) {
        console.log(res.data.resultObject)
        resolve (res.data.resultObject)
      }
    })
  })
};
Vue.prototype.$getCategoryList = CategoryList;


Vue.prototype.$unixTimeToDateTime = function (unixtime) {
  var now = new Date(unixtime*1000); // 依情况进行更改 * 1
  var y = now.getFullYear();
  var m = now.getMonth() + 1;
  var d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
};

window.addEventListener('resize', () => {
  store.commit("changeScreenWidth", document.body.clientWidth)
  store.commit("changeScreenHeight", document.body.clientHeight)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
