import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",//process.env.BASE_+URL
  routes: [
    {
      path: '/login',
      component: () => import('@/view/Login'),
    },
    {
      path: '/index',
      component: () => import('@/view/indexPage'), 
    },
    {
      path: '/balanceRule',
      component: () => import('@/view/h5/balanceRule'), 
    },
    {
      path: '/cancelAccount',
      component: () => import('@/view/h5/cancelAccount'), 
    },
    
    {
      path: '/',
      component: () => import('@/view/index.vue'),
      redirect: '/index',
      children: [
        {
          path: 'FirstOrder',
          component: () => import('@/view/FirstOrder.vue'),
        },
        {
          path: 'Product',
          component: () => import('@/view/Product.vue'),
        },
        {
          path: 'Category',
          component: () => import('@/view/Category.vue'),
        },
        {
          path: 'Activity',
          component: () => import('@/view/Activity.vue'),
        },
        {
          path: 'Poster',
          component: () => import('@/view/Poster.vue'),
        },
        {
          path: 'Post',
          component: () => import('@/view/Post.vue'),
        },
        {
          path: 'Search',
          component: () => import('@/view/Search.vue'),
        },
        {
          path: 'Withdrawal',
          component: () => import('@/view/Withdrawal.vue'),
        },
        {
          path: 'User',
          component: () => import('@/view/User.vue'),
        },
        {
          path: 'Set',
          component: () => import('@/view/Set.vue'),
        },
        {
          path: 'luckyBag',
          component: () => import('@/view/luckyBag.vue'),
        },
        {
          path: 'Trial',
          component: () => import('@/view/Trial/index.vue'),
          children:[
            {
              path: 'list',
              component: () => import('@/view/Trial/list.vue'),
            },
            {
              path: 'auditing',
              component: () => import('@/view/Trial/auditing.vue'),
            },
          ]
        },
      ]
    },
    {
      path: '*',
      redirect: '/index',
    },
  ]
})
// router.beforeEach((to, from, next) => {
//   if (to.path === "/login") {
//     return next()
//   }
//   const tokenStr = window.localStorage.getItem("Authorization")
//   if (!tokenStr) {
//     return next("/login")
//   }
//   next()
// })
export default router